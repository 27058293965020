import {
  required,
  maxLength,
  alpha
} from 'vuelidate/lib/validators';

export default () => {
  return {
    abbrev: {
      Abbreviation: { required, alpha, maxLength: maxLength(8) }
    }
  }
}