var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.shippingLineList')))])]),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.newShippingLine'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.newShippingLine'),\n                placement: 'top-end'\n              }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":_vm.toggleAdd}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"loading":_vm.isLoading,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"active-page":_vm.activePage,"pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"RowNumber",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.RowNumber)+" ")])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Details",fn:function({item, index}){return [_c('td',{staticClass:"text-center center-cell"},[_c('CButtonGroup',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.assignShippingLineRepresentative'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.assignShippingLineRepresentative'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.toggleRepresentantes(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-people"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.assignAcronym'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.assignAcronym'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"plan","size":"sm"},on:{"click":function($event){return _vm.toggleAbreviaturas(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-tags"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.edit')+' '+_vm.$t('label.newShippingLine'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.edit')+' '+$t('label.newShippingLine'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleEdit(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)],1)]}}])})],1)],1)],1)],1)],1),_c('add-linea',{attrs:{"modal":_vm.modalAdd},on:{"update:modal":function($event){_vm.modalAdd=$event},"submited":_vm.handleSubmit}}),_c('edit-modal',{attrs:{"modal":_vm.modalEdit,"linea-data":_vm.linea},on:{"update:modal":function($event){_vm.modalEdit=$event},"submited":_vm.handleSubmit}}),_c('representantes-modal',{attrs:{"modal":_vm.modalRepresentante,"linea-data":_vm.linea},on:{"update:modal":function($event){_vm.modalRepresentante=$event}}}),_c('abreviaturas-modal',{attrs:{"modal":_vm.modalAbreviatura,"linea-data":_vm.lineaAbbrev},on:{"update:modal":function($event){_vm.modalAbreviatura=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }