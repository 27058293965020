<template>
<CModalExtended
  :title="title"
  color="dark"
  size="xl"
  :show.sync="modalActive"
  :close-on-backdrop="false"
   class="modal-content-representante"
>
  <CRow>
    <CCol sm="12" class="d-flex justify-content-end mb-2">
      <div class="search col-sm-10 px-0">
        <CRow>
          <CCol sm="3" class="text-right pr-1">
            <label class="mt-2"> {{ $t('label.client') }} </label>
          </CCol>
          <CCol sm="7">
            <CInput
              v-model.trim="$v.searchQuery.$model"
              :invalid-feedback="errorMessage($v.searchQuery)" 
              :is-valid="hasError($v.searchQuery)"
              v-uppercase
              :placeholder="$t('label.search')"               
              addLabelClasses="required text-right"
              maxlength="50"
              :disabled="asignando"
            >
            </CInput>
          </CCol>
        </CRow>
        <div class="autocomplete-items" v-if="showInputBox">
          <div 
            v-for="cliente in filteredClients"
            :key="cliente.ClientRif"
            @click.stop="handleSelect(cliente)"
          >
            {{ cliente.ClientRif }} - {{ cliente.ClientName }}
          </div>
        </div>
      </div>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
     class="align-center-row-datatable"
        hover
        sorter
        smail
        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        :items="formatedItems"
        :fields="fields"
        :loading="isLoading"
        :items-per-page="5"
        :active-page="activePage"
        pagination
         
      >
        <template #RowNumber="{ item }">
          <td class="center-cell">
            {{ item.RowNumber }}
          </td>
        </template>
        <template #loading>  <loading/> </template>

        <template #Status="{item}">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #Details="{item, index}">
              <!-- :color="statusColor(item)" :disabled="isInactive"-->
          <td class="center-cell"> 
              <CButtonGroup>
                <CButton
                :key="index"
                size="sm"
                color="primary-yellow"
                class="d-flex align-items-center"
                v-c-tooltip="getTooltip(item.Status)"
                @click.stop="statusConfirmation(item)"
                :disabled="isSubmit"
              >
                <CIcon name="cil-new-releases" />
              </CButton>
            </CButtonGroup>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>

  <template #footer>
    <CButton
      color="add"
      class="d-flex align-items-center ml-2"     
      :disabled="isSubmit"
      @click.stop="setRepresentante"
    >
    <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
    </CButton>
    <CButton
      color="wipe"
      class="d-flex align-items-center"
      :disabled="isSubmit"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import { DateFormater } from '@/_helpers/funciones';
import UpperCase  from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import RepresentValidations from '@/_validations/linea-naviera/RepresentFormValidations';

function fields() {
  return[
    { key: 'RowNumber', label: '#', _style: 'width:1%; text-align:center;', filter: false },
    { key: 'Cliente', label: this.$t('label.client'), _classes:'text-uppercase'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:1%;', _classes:'text-uppercase'},
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:1%; ', _classes:'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style:'width:1%; ', _classes:'text-center'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:1%; ', 
      sorter: false, 
      filter: false,
      _classes:'text-center'
    }
]}
//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    asignando: false,
    items: [],
    isLoading: false,
    activePage: 1,
    clienteList: [],
    searchQuery: '',
    isSelected: false,
    cliente: {},
    repreVal: false,
  }
}

//Methods
function getLineaRepresentantes(idLinea) {
  this.isLoading = true;
  let parametros = {
    ShippingLineId: idLinea,
    filter: 'ALL'
  }
  this.$http.get('ShippingLineRepresentative-list', parametros)
  .then(response => {
    this.items = response.data.data;
    this.getClientes();
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function getClientes(){
  
  this.$http.get('Client-list', { Filter: 'ACTIVO' })
  .then(response => {
    this.clienteList = this.filterClientes(response.data.data);
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}
function toggle(newVal) {
  if(newVal){
    this.getLineaRepresentantes(this.lineaData.ShippingLineId);
  }

  this.resetValues();
  this.modalActive = newVal;
}
function resetValues() {
  this.isSelected = false;
  this.cliente = {};
  this.searchQuery = '';
  this.repreVal = false;
  this.$v.$reset();
}
function statusColor(item){
  return item.Status === 'ACTIVO'?'success':'light';
}
function updateStatus(item){
  this.isSubmit = true;
  let LineRepresentativeJson = {
    ClientTpId: item.ClientTpId,
    ShippingLineId: item.ShippingLineId,
    ClientId: item.ClientId,
    Status: item.Status === 'ACTIVO'? 0:1
  };

  this.$http.put('ShippingLineRepresentative-update', LineRepresentativeJson, { root: 'LineRepresentativeJson'})
  .then((response) => {
    this.getLineaRepresentantes(this.lineaData.ShippingLineId);
    this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response.data.data[0].Response,
      type: "success"
    });
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() =>{
    this.isSubmit = false;
  });
}
function filterClientes(clientes){
  let ids = new Set(this.items.map(client => client.ClientId));
  return clientes.filter(client => !ids.has(client.ClientId));
}
function handleSelect(cliente) {
  this.cliente = Object.assign({}, cliente);
  this.searchQuery = `${cliente.ClientRif} - ${cliente.ClientName}`;
  this.isSelected = true;
}
function setRepresentante(){
    try { 
      this.isSubmit = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }
    /*
    if (this.searchQuery === "") {
      this.repreVal = true;
      throw this.$t('label.errorsPleaseCheck');
    }
*/
    this.asignando = true;
    let LineRepresentativeJson = {
      ClientId: this.cliente.ClientId,
      ShippingLineId: this.lineaData.ShippingLineId
    };

    this.$http.post('ShippingLineRepresentative-insert', LineRepresentativeJson, { root: 'LineRepresentativeJson'})
    .then((response) => {
      this.getLineaRepresentantes(this.lineaData.ShippingLineId);
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.searchQuery = '';
      this.resetValues();
      this.isSubmit = false;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.asignando = false;
      this.isSubmit = false;
    });
  } catch (e) {
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error" });
  }

}
function getTooltip(status) {
  switch (status) {
    case 'ACTIVO': 
      return {
        content: this.$t('label.inactivateRepresentative'), //'Inactivar Representante',
        placement: 'top-end'
      };
    case 'INACTIVO':
      return {
        content:  this.$t('label.activateRepresentative'), //'Activar Representante',
        placement: 'top-end'
      };
  }
}
function statusConfirmation(item){
  this.$swal.fire({
    text: `${this.$t('label.changeStatusQuestion')} ${item.ClientName}?`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: '#42AA91',
    cancelButtonColor: '#E1373F',
    confirmButtonText: this.$t('button.confirm'),
    cancelButtonText: this.$t('button.cancel'),
  }).then((result) => {
    if (result.isConfirmed) {
      this.updateStatus(item);
    }
  });
}

//Computeds
function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    Cliente: `${item.ClientRif} - ${item.ClientName}`,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : '',
    _classes: item.Status === 'ACTIVO'? '': 'table-danger'
  }));
}
function title() {
  return this.$t('label.lineRepresentative')+': '+`${this.lineaData.ShippingLineName}`;;
}
function filteredClients(){
  
  if(this.searchQuery === '')
    return [];
  else
    return this.clienteList.filter(cliente =>  {
      return cliente.ClientRif.includes(this.searchQuery) || cliente.ClientName.includes(this.searchQuery)
    });
}
function isDisabled() {
  return !this.isSelected || this.asignando || !this.lineaData.FgActShippingLine;
}
function showInputBox(){
  return filteredClients.length != 0 || !this.isSelected;
}
function isInactive() {
  return this.isSubmit || !this.lineaData.FgActShippingLine;
}

function isSearchQueryValid(repreVal) {
  if(this.repreVal === true){ 
    return (this.searchQuery == null || this.searchQuery == "") ? 'no-valido' : 'valido';
  }
}
export default {
  name: 'representantes',
  mixins: [GeneralMixin],
  props: {
    modal: Boolean,
    lineaData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data,
  validations: RepresentValidations,
  directives: UpperCase,
  methods: {
    getLineaRepresentantes,
    getClientes,
    toggle,
    statusColor,
    updateStatus,
    filterClientes,
    handleSelect,
    resetValues,
    setRepresentante,
    getTooltip,
    statusConfirmation,
    isSearchQueryValid,
  },
  computed: {
    formatedItems,
    title,
    filteredClients,
    isDisabled,
    showInputBox,
    isInactive, 
    fields
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    },
    lineaData: function(newLinea) {
      this.items = [];
      this.clienteList = [];
    },
    searchQuery: function(query){
      if(this.cliente.ClientRif && this.cliente.ClientName){
        let separado = query.split('-');
        if(separado.length != 2)
          this.isSelected = false;
        else{
          this.isSelected = (this.cliente.ClientRif === separado[0].trim() && this.cliente.ClientName === separado[1].trim());
        }
      }


    }
  }
}
</script>

<style lang="scss">
.search {
  position: relative;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  border-bottom: none;
  border-top: none;
  z-index: 10000;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow: auto;
  font-size: small;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4; 
}
.autocomplete-items div:hover {
  background-color: #e9e9e9; 
}
.modal-content-representante {
  .modal-content {
    width: 80% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
      padding-bottom: 0;
    }
  }
}
.center-cell {
  text-align: center;
}
 
</style>