<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{$t('label.shippingLineList')}}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12" class="d-flex justify-content-end">
              <CButton
                color="add"
                class="d-flex align-items-center"
                @click="toggleAdd"
                v-c-tooltip="{
                  content: $t('label.newShippingLine'),
                  placement: 'top-end'
                }"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :loading="isLoading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                :active-page="activePage"
                pagination
              >
                <template #loading>
                  <loading/>
                </template>
                <template #RowNumber="{ item }">
                  <td class="center-cell">
                    {{ item.RowNumber }}
                  </td>
                </template>

                <template #Status="{item}">
                  <td class="center-cell">
                    <CBadge :color="getBadge(item.Status)">
                      {{ $t('label.'+item.Status) }}
                    </CBadge>
                  </td>
                </template>

                <template #Details="{item, index}">
                  <td class="text-center center-cell">
                    <CButtonGroup>
                      <CButton
                        color="watch"                       
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.assignShippingLineRepresentative'),
                          placement: 'top-end'
                        }"
                        @click="toggleRepresentantes(item, index)"
                      >
                        <CIcon name="cil-people" />
                      </CButton>
                      <CButton
                        color="plan"                       
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.assignAcronym'),
                          placement: 'top-end'
                        }"
                        @click="toggleAbreviaturas(item, index)"
                      >
                        <CIcon name="cil-tags" />
                      </CButton>
                      <CButton
                        color="edit"                       
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.edit')+' '+$t('label.newShippingLine'),
                          placement: 'top-end'
                        }"
                        @click="toggleEdit(item, index)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    
    <add-linea :modal.sync="modalAdd" @submited="handleSubmit"/>
    <edit-modal :modal.sync="modalEdit" :linea-data="linea" @submited="handleSubmit"/>
    <representantes-modal :modal.sync="modalRepresentante" :linea-data="linea"/>
    <abreviaturas-modal :modal.sync="modalAbreviatura" :linea-data="lineaAbbrev"/>
  </CRow>
</template>

<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';

import AddLinea  from './add-modal';
import EditModal from './edit-modal';
import RepresentantesModal from './representantes-modal';
import AbreviaturasModal from './abbrev-modal';

import dataTableExtended from '@/components/dataTableExtended';

function fields() {return[
  { key: 'RowNumber', label: '#', _style: 'width:1%; text-align:center;', filter: false },
  { key: 'ShippingLineName', label:this.$t('label.newShippingLine'), _classes:'text-uppercase'},
  { key: 'ShippingLineAbbrev', label: this.$t('label.acronym'), _style:'width:2%;', _classes:'text-uppercase text-center'},
  { key: 'TelefonoP', label: this.$t('label.mainPhone'), _style:'width:18%;', _classes:'text-uppercase'},
  { key: 'EmailP', label:this.$t('label.mainEmail'), _style:'width:1%;', _classes:'text-uppercase'},
  { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:1%;', _classes:'text-uppercase'},
  { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:1%;', _classes:'text-center'},
  { key: 'Status', label: this.$t('label.status'), _style:'width:1%;', _classes:'text-center'},
  { 
    key: 'Details', 
    label: '', 
    _style: 'width:1%;', 
    sorter: false, 
    filter: false, 
    _classes:'text-center'
  }
]}
function data() {
  return {
    items: [],
    linea: {},
    lineaAbbrev: null,
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
    modalRepresentante: false,
    modalAbreviatura: false
  };
}

//*****Funcion created() del ciclo.
function created() {
  this.getLineas();
}

//*****Funcines de watch:
function handlerRoute() {
  return {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    };
}

//*****Funciones de methods:
function getLineas() {
  this.isLoading = true;

  this.$http.get("shipping-agency-list", { filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function pageChange(val) {
  this.$router.push({ query: { page: val }});
}
function toggleAdd() {
  this.modalAdd = true;
}
function toggleEdit(item, index) {
  this.linea = Object.assign({}, item);

  this.modalEdit = true;
}
function toggleRepresentantes(item, index) {
  this.linea = Object.assign({}, item);

  this.modalRepresentante = true;
}
function handleSubmit(){
  this.linea = {};
  this.lineaAbbrev = null;
  this.getLineas();
}
function toggleAbreviaturas(item, index) {
  this.lineaAbbrev = Object.assign({}, item);

  this.modalAbreviatura = true;
}

//*****Funcones de computed:
function formatedItems() {
  let index  = 0;
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    TelefonoP: item.PrimaryPhone? item.PrimaryPhone:'N/A',
    EmailP: item.PrimaryEmail? item.PrimaryEmail:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

export default {
  name: 'lineas',
  mixins: [GeneralMixin],
  components: {
    AddLinea,
    EditModal,
    RepresentantesModal,
    AbreviaturasModal,
    dataTableExtended
  },
  data,
  created,
  watch: {
    $route: handlerRoute
  },
  methods: {
    getLineas,
    pageChange,
    toggleAdd,
    toggleEdit,
    toggleRepresentantes,
    toggleAbreviaturas,
    handleSubmit,
  },
  computed: {
    formatedItems,
    fields
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>